/* You can add global styles to this file, and also import other style files */

/* @import "./assets/style/material-custom.css"; */
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-dark.css";

@import "https://fonts.googleapis.com/css?family=Roboto:400,500,700";
@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.css";
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css";
@import "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css";
@import "https://cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "https://fonts.googleapis.com/css?family=ABeeZee&display=swap";
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Signika+Negative&family=Signika:wght@600&display=swap');


/* .......................Login start ........................... */
body {
  display: block !important;
  /* font-family: 'Signika', sans-serif; */
  line-height: normal;
  height: calc(100vh-75px);
  /* background-image: linear-gradient(-225deg, #2CD8D5 0%, #6B8DD6 48%, #8E37D7 100%); */
  margin-bottom: 44px;
  background: transparent;
  overflow: hidden;
}

html {
  /* background-image: url(../src/assets/img/blurredbg.png); */
  background-color: #ffffff;
  /* background-image: url(./assets/img/purple-and-white.jpg); */
  /* background-blend-mode:difference; */
  /* background: white; */
  /* background: rgb(35,34,41);
  background: radial-gradient(circle, rgba(35,34,41,1) 44%, rgba(94,4,219,1) 80%, rgba(39,24,56,1) 93%); */
  background-size: cover;
  object-fit: fill;
  width: 100%;
  /* height: 100%; */
  max-height: calc(100vh-65px);
}

html,
body {
  margin: 0;
}

.full-width {
  width: 100vw;
}

.content {
  min-height: 100vh;
  margin: 0 auto 10px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

:host {
  background-color: #ffffff;
}

.grediant-bg {
  background-image: linear-gradient(141deg, #4ec1e3 0%, #4bc9c4 51%, #49ceb4 75%);
}

.container-bg {
  background-color: #4ab6b0;
  background-image: url(./assets/img/logo-bg.svg);
  background-size: cover;
  /* background-image: linear-gradient(315deg, #f6f6f6 0%, #e9e9e9 74%); */
}

.main-grediant-bg {
  /* background-image: url(../src/assets/img/headfoot.svg); */
  background-color: #2876f9;
  background-image: linear-gradient(315deg, #000000 0%, #1d0636 74%);
  background-size: cover;
  /* background-image: linear-gradient(141deg, #000000 0%, #cc0000 51%, #0c4458 75%); */
  /* background-image: linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%); */
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

button:focus {
  outline: none;
}

button {
  cursor: pointer;
}

.submit {
  margin-right: 12px;
  color: #fff;
  background-color: #909090;
  border-color: #909090;
  border-radius: 50px;
  width: 50px;
  font-weight: 600;
  cursor: pointer;
  opacity: 1 !important;
}

.filter-btn {
  border: 2px solid #fff !important;
  width: 160px !important;
}

.submit:hover {
  color: #909090;
  background-color: #fff;
  border-color: #909090;
}

.btn-primary:not(:disabled):not(.disabled):active {
  color: #000;
  background-color: #fff;
  border-color: #000000;
  box-shadow: none !important;
}

#return-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.7);
  width: 50px;
  height: 50px;
  display: block;
  cursor: pointer;
  text-decoration: none;
  border-radius: 35px;
  display: none;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top i {
  color: #fff;
  margin: 0;
  position: relative;
  left: 16px;
  top: 13px;
  font-size: 19px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#return-to-top:hover {
  background: rgba(0, 0, 0, 0.9);
}

#return-to-top:hover i {
  color: #fff;
  top: 5px;
}


/* .........Filter Start........... */


.filter {
  padding: 5px;
  width: 200px;
  position: fixed;
  z-index: 8 !important;
  top: 44px;
  height: 100%;
  right: 0;
  z-index: 9;
  background: #333;
  -webkit-transition: .3s ease-in-out all;
  -o-transition: .3s ease-in-out all;
  transition: .3s ease-in-out all;
}

.filter input {
  border-radius: 50px !important;
  color: #35038b;
}

.filter-img {
  width: 20px;
  margin-right: 5px;
}

.show-sidebar {
  display: none;
}

.closebtn a {
  font-size: 30px;
  color: #fff !important;
  text-align: right;
  padding-left: 20px;

}

.closebtn {
  display: none;
}

.w3-animate-right {
  -webkit-animation: animateright .4s;
  animation: animateright .4s
}

.dropzone {
  height: 150px;
  width: 100%;
  border-radius: 5px;
  /* display: table; */
  border: 2px dashed #ccc;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  margin-top: 4px;
  text-align: center;
  line-height: 150px;
  height: 150px;
  position: relative;
}

.dropzone:hover {
  box-shadow: 0 10px 10px #ccc;

  background: #fff;
}

.dropzone:hover span,
.dropzone:hover span .fa {
  color: rgb(33, 1, 48);
}

.dropzone span {
  vertical-align: middle;
  display: table-cell;
  text-align: center;
}

.dropzone span .fa {
  font-size: 34px;
  color: #495057;
  vertical-align: middle;
}

.bg-white {
  background: #fff;
}

.clr_white {
  color: #fff;
}

/* ============ Profile Sidebar Details ============ */

.sidebar-header {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #212529;
  max-width: 100%;
}

.sidebar-header li {
  padding: 5px;
}

.sidebar-header li:last-child {
  border-bottom: none;
}

.sidebar-header li span {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.sidebar-header li span i {
  margin-right: 8px;
  font-size: 16px;
  width: 7%;
  color: #030303;
}

.sidebar-header li:hover {
  border-left: 5px solid #929191;
  cursor: default;
  padding-left: 0;
  background-color: #eaeaea;
}

.sidebar-header li:hover span i,
.sidebar-header li:hover span {
  color: #5d4037;
}

/* ============ Profile Sidebar Details End ============ */

@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0
  }

  to {
    right: 0;
    opacity: 1
  }
}


/* ...........Filter End............ */






/* ------------------- Scrollbar UI ------------------------ */

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #00000025;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: darkslateblue;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #35038b;
}


@media (max-width:1024px) {
  .prof-user-details ul {
    max-width: 100%
  }

  .tasks-tab>.row {
    width: 100%;
  }

  .prof-user-details ul li a {
    padding: 0;
  }

  .task-header.body {
    margin: 0;
  }

  .task-header {
    margin: 10px 0 0;
  }

}

@media (min-width:992px) {
  .filter {
    display: block !important;
  }
}

@media (max-width:992px) {
  .name_level h2 {
    font-size: 20px;
  }

  .price-asign button {
    font-size: 11px;
    padding: 2px 6px;
  }

  .TaskScroll .list-group-item {
    padding: 1px;
  }

  .p-center {
    width: 85%;
  }

  .p-level {
    padding-right: 5px;
  }

  .show-sidebar {
    display: block;
    position: absolute;
    right: 0;
    z-index: 8;
    top: 0;
    width: 60px;
    -webkit-box-shadow: 0 0 10px #ccc;
    box-shadow: 0 0 10px #ccc;
    border-radius: 50px 0 0 50px;
    height: 100px;
    cursor: pointer;
  }

  .show-sidebar.admin {
    top: 33px !important;
  }

  #mySidebar {
    display: none;
  }

  .closebtn {
    display: block;
    cursor: pointer;
  }

  .employees {
    padding: 0;
  }

  .text-info {
    margin: 0;
  }

  .filter-img-tab {
    width: 85%;
    margin-left: 7px;
  }

  .tab-mg-0-auto {
    margin: 10px auto;
  }

}

@media (min-width:993px) and (max-width:1279px) {
  .employ-1024 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 40.333333%;
    flex: 0 0 40.333333%;
  }

  .btn-success.add {
    right: 30px;
  }

}

@media (max-width:767px) {
  .card {
    width: 280px;
  }

  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;

  }

  #exampleModal .modal-dialog {
    max-width: 90%;
    margin: 20px auto;
  }

  .popup-img {
    width: 70px;
    height: 70px;
    left: -21px;
    top: -45px;
    margin-bottom: 0 !important;
  }

  #exampleModal .modal-body {
    padding: 0;
  }

  .p-center {
    width: 88%;
  }

  .p-name h3 {
    font-size: 22px;
  }

  .p-name span {
    font-size: 14px;
  }

  .empLevel {
    font-size: 34px;
  }

  i {
    font-size: 14px;
  }

  .basicInfo {
    font-size: 14px;
  }

  .admin-employ {
    padding: 3px 20px;
  }

  .asign-task>div {
    width: 100%;
    padding: 5px;
  }

  .asign-button .submit {
    width: 60%;
  }

  .admin-employ h5 {
    font-size: 16px;
  }

  .admin-employ p {
    font-size: 13px;
  }

  .admin-employ .text-muted {
    font-size: 13px;
  }

  .task-view-update {
    text-align: left !important;
  }

  .task-view-update button {
    font-size: 12px;
  }

  .task-view-update .text-muted {
    margin-left: 0px;
  }
}

@media (max-width:500px) {
  .pd-0-mob {
    padding: 0;
  }

  .btn-success.add i {
    font-size: 22px;
    vertical-align: middle;
  }

  .btn-success.add {
    margin: 0;
    width: 40px;
    height: 40px;
  }

  .s_emps_name_level {
    text-align: center;
    padding: 5px;
  }

  .s_emps>div {
    width: 200px;
    height: 200px;
  }

  .s_emps .card-img-top {
    height: 60px;
    width: 60px;
  }

  .s_emps {
    width: 200px;
    height: 200px;
    margin: 5px;
  }

  .price-asign button {
    font-size: 11px;
    padding: 2px 6px;
  }

  .signin-bg .container {
    padding: 0;


  }

  .form-sample {
    width: 100%;
  }

  .other-logins {
    padding: 0 50px;
  }

  .login-head h2 {
    font-size: 16px;
  }

  .tasks-tab p {
    font-size: 13px;
    margin: 5px 0;
  }
}

@media (min-width: 576px) {
  .mt-sm-5 {
    margin-top: 1rem !important;
  }

}

.signinwithemail {
  font-weight: 600;
  cursor: pointer;
}

.form-sample {
  position: static;
  height: 100%;
  margin: 15px auto;
  box-shadow: 0 0 10px rgb(0, 197, 204);
  background: rgba(5, 5, 5, 0.171);
  width: 400px;
  padding: 20px;
  color: white;
}

.form-sample-sign-up {
  position: static;
  height: 100%;
  margin: 15px auto;
  box-shadow: 0 0 10px #ccc;
  background: rgba(0, 0, 0, 0.13);
  width: 75vw;
  padding: 20px;
}

.other-logins {
  padding: 0 60px;
}

.form-sample .ballon>div>p {
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #fff;
  margin: 0 0 10px
}

button.face {
  background: #3b5998;
  font-family: 'Roboto', sans-serif;
}

button.g {
  background: #d34836
}

.login_frgt_pas {
  color: rgb(1, 255, 234);
  cursor: pointer
}

.login_submit {
  margin: 10px 0 0
}

.pd-5-0 {
  padding: 5px 0
}

.pd-5 {
  padding: 5px;
}

.btn.full:hover {
  box-shadow: 0 0 10px #757474;
  color: #fff;
}

.btn.full:hover .fa {
  transform: rotate(360deg);
  -webkit-transition: .3s ease-in-out all;
  -o-transition: .3s ease-in-out all;
  transition: .3s ease-in-out all;
}

.btn.full {
  width: 100%;
  margin: 5px 0;
  margin-right: 0;
  border: none;
  height: 50px;
  color: #fff;
  border-radius: 0;
  box-shadow: 0 0 5px #ccc;
}

.lin {
  background-color: #0077B5;
}

.chkbox {
  height: 20px !important;
}

.btn-reg {
  background: #fbfbfb;
  color: #333 !important;
}

.btn-reg:hover {
  color: #000 !important;
}

.or {
  position: relative;
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.or h2 {
  font-size: 18px;
}

.left-line,
.right-line {
  position: absolute;
  top: 10px;
  width: 23%;
  background: #ccc;
  height: 2px;
}

.left-line {
  left: 0;
}

.right-line {
  right: 0;
}

.signinwithemail:hover,
.login_frgt_pas:hover {
  text-decoration: underline;
  color: #d83f87;
  text-shadow: black;
}

.other-logins .fa {
  line-height: 24px;
}

.btn-signin {
  background: #333;
  width: 240px;
  color: #fff;
  box-shadow: 0 0 5px #ccc;
  border-radius: 0;
}

.btn-signin:hover {
  color: #fff !important;
  box-shadow: 0 0 10px #757474;
}

.btn-signin i {
  line-height: 24px;
  float: left;
  margin: 0;
}

form span strong {
  color: #c00;
  font-size: 13px;
}

.signup-page .left-line,
.signup-page .right-line {
  width: 35px;
}

.mg-0-auto {
  margin: 0 auto;
}

/* ------------------- Login End ------------------------ */

.input-group-prepend .input-group-text {
  width: 100%;
}

/* ------------------------- add candidate ------------------------- */
.display-step {
  display: block !important;
}

.hide-step {
  display: none !important;
}


.dropzone input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.dropzone input[type=file] {
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.dropzone span {
  position: relative;
  top: 5px;
}

.set-position {
  top: -76px !important;
}

/******************** NEON COLORS *********************/

/* FF0066 = Neon Pink
FF00 = Neon Green
FFFF00 = Neon Yellow
FF3300 = Neon Orange
FF0009 = Neon Red
56600FF = Neon Purple
FF99 = Neon Mint Green
BF = Neon Dark Blue
FF = Neon Blue
FFFF = Light Blue */

.bg-neon-green {
  background-color: #39ff14
}

.bg-neon-yellow {
  background-color: #ccff00
}

.bg-neon-orange {
  background-color: #FF3300
}

.bg-neon-red {
  background-color: #FF0009
}

.bg-neon-purple {
  background-color: #8a2be2
}

.bg-neon-mint-green {
  background-color: #98ff98
}

.bg-neon-dark-blue {
  background-color: #1164b4
}

.bg-neon-blue {
  background-color: #6600FF
}

.bg-maya-blue {
  background-color: #73c2fb
}

.text-neon-green {
  color: #39ff14
}

.text-neon-yellow {
  color: #ccff00
}

.text-neon-orange {
  color: #FF3300
}

.text-neon-red {
  color: #FF0009
}

.text-neon-purple {
  color: #8a2be2
}

.text-neon-mint-green {
  color: #98ff98
}

.text-neon-dark-blue {
  color: #1164b4
}

.text-neon-blue {
  color: #6600FF
}

.text-maya-blue {
  color: #73c2fb
}

/* 3D Button */
.btn-3d {
  position: relative;
  display: inline-block;
  font-size: 22px;
  padding: 20px 60px;
  color: white;
  margin: 20px 10px 10px;
  border-radius: 6px;
  text-align: center;
  transition: top .01s linear;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
}

.btn-3d.red:hover {
  background-color: #e74c3c;
}

.btn-3d.blue:hover {
  background-color: #699DD1;
}

.btn-3d.green:hover {
  background-color: #80C49D;
}

.btn-3d.purple:hover {
  background-color: #D19ECB;
}

.btn-3d.yellow:hover {
  background-color: #F0D264;
}

.btn-3d.cyan:hover {
  background-color: #82D1E3;
}

.btn-3d:active {
  top: 9px;
}

/* 3D button colors */
.btn-3d.red {
  background-color: #e74c3c;
  box-shadow: 0 0 0 1px #c63702 inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 #C24032,
    0 8px 0 1px rgba(0, 0, 0, 0.4),
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.btn-3d.red:active {
  box-shadow: 0 0 0 1px #c63702 inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 0 0 1px rgba(0, 0, 0, 0.4);
}

.btn-3d.blue {
  background-color: #6DA2D9;
  box-shadow: 0 0 0 1px #6698cb inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 rgba(110, 164, 219, .7),
    0 8px 0 1px rgba(0, 0, 0, .4),
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.btn-3d.blue:active {
  box-shadow: 0 0 0 1px #6191C2 inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 0 0 1px rgba(0, 0, 0, 0.4);
}

.btn-3d.green {
  background-color: #82c8a0;
  box-shadow: 0 0 0 1px #82c8a0 inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 rgba(126, 194, 155, .7),
    0 8px 0 1px rgba(0, 0, 0, .4),
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.btn-3d.green:active {
  box-shadow: 0 0 0 1px #82c8a0 inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 0 0 1px rgba(0, 0, 0, 0.4);
}

.btn-3d.purple {
  background-color: #cb99c5;
  box-shadow: 0 0 0 1px #cb99c5 inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 rgba(189, 142, 183, .7),
    0 8px 0 1px rgba(0, 0, 0, .4),
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.btn-3d.purple:active {
  box-shadow: 0 0 0 1px #cb99c5 inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 0 0 1px rgba(0, 0, 0, 0.4);
}

.btn-3d.cyan {
  background-color: #7fccde;
  box-shadow: 0 0 0 1px #7fccde inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 rgba(102, 164, 178, .6),
    0 8px 0 1px rgba(0, 0, 0, .4),
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.btn-3d.cyan:active {
  box-shadow: 0 0 0 1px #7fccde inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 0 0 1px rgba(0, 0, 0, 0.4);
}

.btn-3d.yellow {
  background-color: #F0D264;
  box-shadow: 0 0 0 1px #F0D264 inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 8px 0 0 rgba(196, 172, 83, .7),
    0 8px 0 1px rgba(0, 0, 0, .4),
    0 8px 8px 1px rgba(0, 0, 0, 0.5);
}

.btn-3d.yellow:active {
  box-shadow: 0 0 0 1px #F0D264 inset,
    0 0 0 2px rgba(255, 255, 255, 0.15) inset,
    0 0 0 1px rgba(0, 0, 0, 0.4);
}

.flip-card {
  /* cursor: pointer; */
  background-color: transparent;
  width: 100%;

  perspective: 1000px;
  font-size: 12px;
  overflow-x: hidden;
  overflow-y: hidden;
  border-color: rgba(128, 128, 128, 0.26);
  border-right-style: thin;
  border-bottom-style: thin;
}

.flip-card-inner {
  position: relative;
  width: 99%;
  margin-left: auto;
  margin-right: auto;
  height: 39vh;
  transition: transform 0.4s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);

}

.flip-card-click-front {
  transform: rotateY(180deg);
}

.flip-card-click-back {
  transform: rotateY(0deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 40vh;
  backface-visibility: hidden;
  overflow: hidden;
}

.flip-card-front {
  color: black;
  background-color: white;
}

.flip-card-back {
  color: black;
  background-color: white;


  transform: rotateY(900deg);
}

/* ---------- toggle --------------- */
.switch {
  position: relative;
  display: inline-block;
  width: 100%;
  /* height: 34px; */

}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 80%;
  width: 50px;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: rgb(78, 118, 151);
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.roleMsg {
  color: #f00;
  font-weight: 500;
}

/* ---------- toggle end --------------- */

.input-group-text {
  background-color: #f3f5f7;
}

.position-relative {
  position: relative;
}

.position-relative i {
  position: absolute;
  right: 10px;
  font-size: 22px;
  top: 7px;
}

/* centered columns styles */
.row-centered {
  text-align: center;
}

.col-centered {
  margin: 0 auto
}

/* ========= Slider */

.innerSlider {
  display: block !important;
  bottom: 20px;
  width: 90%;
  margin: 0 auto;
}

.innerSlider .slick-slide {
  cursor: pointer;
  border: 1px solid #D3D3D3;
  height: 105px;
  padding: 15px;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 4px;
  box-shadow: #6600FF;
  width: 90%;
}



.leftRsBanner,
.rightRsBanner {
  display: block;
  top: 50%;
  outline: 0;
  margin-top: -22px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: #35038b !important;
  position: absolute;
}

.leftRsBanner {
  color: #fff;
  left: -30px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: .5s all;
  -o-transition: .5s all;
  transition: .5s all;
  border: 1px solid #673ab7;
}

.rightRsBanner {
  color: #fff;
  right: -28px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transition: .5s all;
  -o-transition: .5s all;
  transition: .5s all;
  border: 1px solid #673ab7;
}

/* ------------ End Slider */

/* Ribbon */
.ribbon {
  display: inline-block;
  margin: 5px;
  position: relative;
  background-image: linear-gradient(to right top, #ffffff, #fefeff, #fdfdfe, #fcfdfe, #fbfcfe, #fafcfe, #f9fbfe, #f8fbfe, #f7fbfe, #f6fcfe, #f5fcfd, #f4fcfc);
  z-index: 1;
  width: 100%;
  height: fit-content;
  border-style: solid;
  border-width: thin;
  border-color: white;
  border-radius: 2px;
  margin-top: auto;
  margin-bottom: 8px;
  font-size: xx-large;
}

.ribbon-text {
  font-size: 1.1em;
  color: rgb(0, 0, 0);
  padding-left: 14px;
  margin-left: 4px;
}

.ribbon:after {
  content: '';
  border: 15px solid rgba(255, 255, 255, 0);
  border-right-color: transparent;
  border-right-width: 21px;
  position: absolute;
  top: 0;
  right: -27px;

}

/* End */
.candidate-name {
  font-size: 1em;
  /* text-shadow: 0 0 1.5px; */
  font-weight: bolder;
  color: black;
  text-align: center;
}

.mat-chip {
  font-size: 10px !important;

}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 4px;
}

/* .sub-header-bg { */
  /* background-color: rgba(32, 5, 82, 0.89); */
  /* background: -webkit-linear-gradient(bottom, rgba(226, 226, 226, 0.849), #BABDBC);
  background: -moz-linear-gradient(bottom, rgba(226, 226, 226, 0.808), #BABDBC);
  background: linear-gradient(to top, rgba(226, 226, 226, 0.774), #BABDBC); */
/* } */

.box {
  margin: 25px;
  width: 250px;
  height: 48vh;
  background: #fff;
  border: solid black;
  border-color: black;
  float: left;


}

.box1 {
  border-width: 3px 4px 3px 5px;
  border-radius: 95% 4% 92% 5%/4% 95% 6% 95%;
  transform: rotate(2deg);
  overflow: scroll;
}

.box2 {
  width: 350px;
  border-width: 3px 3px 5px 5px;
  border-radius: 4% 95% 6% 95%/95% 4% 92% 5%;
  transform: rotate(-2deg);
}

.box3 {
  border-width: 5px 3px 3px 5px;
  border-radius: 95% 4% 97% 5%/4% 94% 3% 95%;
  transform: rotate(2deg);
}

.oddboxinner {
  margin: 15px;
  transform: rotate(-2deg);
}

.evenboxinner {
  transform: rotate(2deg);
  margin: 15px;
  padding: 0 5px;
  float: right;
  background: #ddd;
  border: 1px solid #222;
  box-shadow: 3px 3px 0 #222;
}

::ng-deep .col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding: 0px;
  ;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.badge {
  white-space: pre-wrap;
}

.badge-primary {
  background-color: rgba(218, 165, 32, 0.404);
}

.badge-red {
  background-color: rgba(255, 0, 0, 0.226);
  color: black;
}

.badge-required {
  text-align: left;
  background-image: linear-gradient(135deg, rgba(252, 0, 218, 0.123) 10%, rgba(210, 29, 255, 0.199) 100%);
  color: black;
  font-style: normal;

  cursor: grab;
}

.badge-preferred {
  text-align: left;
  background-image: linear-gradient(135deg, rgba(252, 252, 252, 0.212) 10%, rgba(200, 206, 198, 0.5) 100%);
  color: black;
  font-style: normal;
  font-size: smaller;
  cursor: grab;


}

.badge-industry {
  background: linear-gradient(90deg, #2d3131 0%, #020416 100%) !important;
  color: white;
  font-style: normal;
  font-size: large;
  width: fit-content;
  cursor: grab;
  vertical-align: middle;
  height: 35px;
}

.badge-findersfee {

  background: #ffffff;
  color: rgb(0, 0, 0);
  font-style: normal;
  font-size: x-large;
  width: fit-content;
  margin-bottom: 5px;
}

.badge-type-level {
  background: #3ab6d50c;
  color: rgb(0, 0, 0);
  font-style: normal;
  font-size: medium;
  width: fit-content;

}

.badge-responsibilities {
  background: rgb(245, 245, 245);
  color: rgb(0, 0, 0);
  font-style: normal;
  font-size: medium;
  width: fit-content;
  padding: 4px;

}

.pointer {
  cursor: pointer;
}

/* Centers on screen */
.x {
  vertical-align: middle;
  width: 30vw;
  position: absolute;
  top: 50%;

  left: 50%;
  margin-left: -15vw;
  /* margin-top: auto; */
  margin-bottom: auto;
  height: 65vh;
  margin-top: -32.5vh;

  /*half the size of width*/
}

.text-posting-labels {
  color: rgb(40, 3, 75);
  font-weight: bolder;
}

.threed:hover {
  box-shadow:
    1px 1px #0df0f83b,
    2px 2px #0df0f83b,
    3px 3px #0df0f83b;
  -webkit-transform: translateX(-3px);
  transform: translateX(-3px);
}



.font-sm {
  padding: 3px;
  margin: 1px;
  font-size: 12px;
}

.request-list {
  list-style: flex;
  height: fit-content;
  padding: 0;
}

.request-items {
  text-align: right;
  padding: 8 8px;
  margin: 5px 0;
  border-bottom: 1px solid #ccc;
}

.request-head {
  font-size: 18px;
  margin: 20px 0 5px;
}

.request-title {
  background: #e2e2e2;
  border-radius: 5px;
  float: left;
  padding: 5px;
  vertical-align: middle;
}

/* Centered text */
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pac-container {
  z-index: 1400;
}

.mogum-tags {
  max-width: fit-content;
  padding-top: 3px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 3px;
  background-color: rgb(56, 17, 92);
  color: white;
  margin: 2px;
  border-radius: 22px;
  cursor: pointer;
}

.mat-calendar-body-cell-content {
  z-index: 0 !important;
}

/* Messing w/ Glassmorphism */
.gl-morph-grey {
  background: rgba(104, 104, 104, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.0px);
  -webkit-backdrop-filter: blur(10.0px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: black;
}

.gl-morph-purple {
  background: rgba(129, 113, 148, 0.30);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.0px);
  -webkit-backdrop-filter: blur(10.0px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gl-morph-gold {
  background: rgba(248, 231, 28, 0.15);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0.5px);
  -webkit-backdrop-filter: blur(0.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gl-morph-white {
  background: rgba(252, 252, 252, 0.60);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.0px);
  -webkit-backdrop-filter: blur(10.0px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gl-morph-pink {
  background: rgba(255, 218, 254, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.0px);
  -webkit-backdrop-filter: blur(10.0px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gl-morph-lt-grey {
  background: rgba(192, 192, 192, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.0px);
  -webkit-backdrop-filter: blur(10.0px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gl-morph-green {
  background: rgba(65, 117, 5, 0.35);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(10.0px);
  -webkit-backdrop-filter: blur(10.0px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gl-morph-red {
  background: rgba(208, 2, 27, 0.30);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0.5px);
  -webkit-backdrop-filter: blur(0.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gl-morph-black {
  background: rgba(0, 0, 0, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0.5px);
  -webkit-backdrop-filter: blur(0.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.gl-morph-light-white {
  background: rgba(255, 255, 255, 0.80);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(0.5px);
  -webkit-backdrop-filter: blur(0.5px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}

.arial-text {
  font-family: Arial, Helvetica, sans-serif;
}

.mat-drawer-container {
  background: transparent;
}

.mat-drawer-content {
  background: transparent;
}

.mat-drawer {
  background: transparent;
}

.example {
  background-color: #eee;
  width: 200px;
  height: 100px;
  border: 1px dotted black;
  overflow-y: scroll; /* Add the ability to scroll */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide_scrollbar_example::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide_scrollbar_example {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}